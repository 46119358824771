import { userAxios } from '../index'

/**
 * It returns a promise that resolves to the data property of the response object returned by the
 * bonusAxios.get() method.
 * @param data - {
 * @returns The data from the API call.
 */
const bonusList = async perPage => {
  try {
    console.log('bonusList')
    return await userAxios.get(`bonus/all/${perPage}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const bonusPagination = async (perPage, page) => {
  try {
    return await userAxios.get(`bonus/all/${perPage}?page=${page}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

/**
 * It takes in a data object, and then sends a post request to the server with the data object.
 * @param data - {
 */
const bonusCreate = async data => {
  try {
    return await userAxios.post('bonus', data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

/**
 * It's an async function that makes a GET request to the bonusAxios instance, and returns the data from
 * the response.
 * @param id - the id of the bonus you want to get the details of
 */
const bonusDetail = async id => {
  try {
    return await userAxios.get(`bonus/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

/**
 * It takes in an id and data, and then it makes a put request to the server with the id and data.
 * @param id - the id of the bonus you want to update
 * @param data - {
 */
const bonusUpdate = async (id, data) => {
  try {
    return await userAxios.put(`bonus/${id}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

/**
 * This function will delete a bonus from the database, and return the deleted bonus's data.
 * @param id - the id of the bonus you want to delete
 */
const bonusDelete = async id => {
  try {
    return await userAxios.delete(`bonus/${id}`).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const bonusUpdateFacilities = async (id, data) => {
  try {
    return await userAxios.put(`bonus/clinic/${id}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const bonusFilter = async (perPage, data) => {
  try {
    return await userAxios.post(`bonus/search/${perPage}`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

const bonusByUser = async data => {
  try {
    return await userAxios.post(`bonus/by-user`, data).then(res => res.data)
  } catch (error) {
    console.log(error)
  }
}

export default {
  bonusList,
  bonusPagination,
  bonusCreate,
  bonusUpdate,
  bonusDelete,
  bonusDetail,
  bonusUpdateFacilities,
  bonusFilter,
  bonusByUser,
}
