<template>
  <b-card>
    <b-row>
      <b-col>
        <b-row align-h="between">
          <b-col cols="auto">
            <b-row>
              <b-col cols="auto">
                <b-avatar variant="primary" size="40" class="shadow mb-2">
                  <i class="fas fa-coins text-white cursor-pointer icon-2x" />
                </b-avatar>
              </b-col>
              <b-col align-self="center" cols="auto">
                <h1>Bonus Details</h1>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="auto">
            <b-dropdown right text="Filters" variant="primary">
              <b-dropdown-form>
                <b-form-checkbox-group v-model="selectedFilters">
                  <b-form-checkbox value="value_achieved">Value Achieved</b-form-checkbox>
                  <b-form-checkbox value="base_bonus">Base Bonus</b-form-checkbox>
                  <b-form-checkbox value="extra_bonus">Extra Bonus</b-form-checkbox>
                  <b-form-checkbox value="appointments_attended"
                    >Appointments Attended</b-form-checkbox
                  >
                  <b-form-checkbox value="total">Total</b-form-checkbox>
                </b-form-checkbox-group>
              </b-dropdown-form>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <!-- Gráfico de líneas -->
      <b-col cols="12">
        <vue-apex-charts
          v-if="typeChart === 1"
          :options="chartOptions"
          :series="series"
          height="450"
          type="line" />
      </b-col>
      <!-- Gráficos de barras radiales -->
      <!-- <b-col cols="3">
        <b-row v-for="({ label, value }, key) in avgs" :key="key">
          <vue-apex-charts
            :options="radialBarOptions(label, value)"
            :series="[percentage(value)]"
            type="radialBar"
          />
        </b-row>
      </b-col> -->
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BDropdown,
  BDropdownForm,
  BFormCheckboxGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import axiosB from '@/core/services/api/admin/bonus'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BDropdown,
    BDropdownForm,
    BFormCheckboxGroup,
    BFormCheckbox,
    VueApexCharts,
  },
  data() {
    return {
      bonusData: [], // Datos de bonificaciones obtenidos de la API
      selectedFilters: [
        'value_achieved',
        'base_bonus',
        'extra_bonus',
        'appointments_attended',
        'total',
      ], // Filtros seleccionados
      typeChart: 1, // Tipo de gráfico (1: línea, 2: barras, etc.)
      chartOptions: {
        chart: {
          id: 'bonus-line-chart',
          toolbar: {
            show: true,
          },
        },
        xaxis: {
          categories: [], // Días (se llenará dinámicamente)
        },
        yaxis: {
          title: {
            text: 'Value',
          },
        },
        stroke: {
          curve: 'smooth',
        },
      },
      series: [], // Datos para el gráfico de líneas
      avgs: [], // Datos para los gráficos de barras radiales
    }
  },
  computed: {
    // Filtra los datos según los filtros seleccionados
    filteredBonusData() {
      return this.bonusData.map(bonus => {
        const filteredBonus = { day: bonus.day }
        if (this.selectedFilters.includes('value_achieved')) {
          filteredBonus.value_achieved = bonus.value_achieved
        }
        if (this.selectedFilters.includes('base_bonus')) {
          filteredBonus.base_bonus = bonus.base_bonus
        }
        if (this.selectedFilters.includes('extra_bonus')) {
          filteredBonus.extra_bonus = bonus.extra_bonus
        }
        if (this.selectedFilters.includes('appointments_attended')) {
          filteredBonus.appointments_attended = bonus.appointments.length
        }
        if (this.selectedFilters.includes('total')) {
          filteredBonus.total = bonus.total
        }
        return filteredBonus
      })
    },
  },
  async mounted() {
    this.loading = true // Activa el estado de carga

        // Obtén el userData desde el localStorage
    const userDataString = localStorage.getItem('userData')
    if (userDataString) {
      this.userData = JSON.parse(userDataString) // Convierte el string a objeto
      console.log('🚀 ~ Datos del usuario:', this.userData)
    }
    
    try {
      // Llama a la API para obtener los datos de bonificaciones
      const response = await axiosB.bonusByUser({
        user_id: this.userData.id,
        rol_id: this.userData.roles[0].id,
        // user_id: 1,
        // rol_id: 4,
      })

      // Asigna los datos de la API a bonusData
      this.bonusData = response.data.bonus // Accede a la propiedad "bonus"
      console.log("🚀 ~ mounted ~ response.data.bonus:", response.data.bonus)

      // Prepara los datos para los gráficos
      this.prepareChartData()
      this.prepareRadialBarData()
    } catch (error) {
      console.error('Error fetching bonus details:', error)
    } finally {
      this.loading = false // Desactiva el estado de carga
    }
  },
  methods: {
    // Prepara los datos para el gráfico de líneas
    prepareChartData() {
      // Extrae el día del mes de la fecha (por ejemplo, "22" de "2025-01-22")
      this.chartOptions.xaxis.categories = this.bonusData.map(bonus => {
        const date = new Date(bonus.day) // Convierte la cadena de fecha a un objeto Date
        return date.getDate() // Obtiene el día del mes (1-31)
      })

      // Prepara las series para el gráfico de líneas
      this.series = [
        {
          name: 'Value Achieved',
          data: this.bonusData.map(bonus => bonus.value_achieved),
        },
        {
          name: 'Base Bonus',
          data: this.bonusData.map(bonus => bonus.base_bonus),
        },
        {
          name: 'Extra Bonus',
          data: this.bonusData.map(bonus => bonus.extra_bonus),
        },
        {
          name: 'Appointments Attended',
          data: this.bonusData.map(bonus => bonus.appointments.length),
        },
        {
          name: 'Total',
          data: this.bonusData.map(bonus => bonus.total),
        },
      ]
    },
    // Prepara los datos para los gráficos de barras radiales
    prepareRadialBarData() {
      // Calcula los promedios o valores totales para los gráficos radiales
      const totalValueAchieved = this.bonusData.reduce(
        (sum, bonus) => sum + bonus.value_achieved,
        0
      )
      const totalBaseBonus = this.bonusData.reduce(
        (sum, bonus) => sum + bonus.base_bonus,
        0
      )
      const totalExtraBonus = this.bonusData.reduce(
        (sum, bonus) => sum + bonus.extra_bonus,
        0
      )
      const totalAppointmentsAttended = this.bonusData.reduce(
        (sum, bonus) => sum + bonus.appointments.length,
        0
      )
      const totalTotal = this.bonusData.reduce((sum, bonus) => sum + bonus.total, 0)

      this.avgs = [
        { label: 'Value Achieved', value: totalValueAchieved },
        { label: 'Base Bonus', value: totalBaseBonus },
        { label: 'Extra Bonus', value: totalExtraBonus },
        { label: 'Appointments Attended', value: totalAppointmentsAttended },
        { label: 'Total', value: totalTotal },
      ]
    },
    // Opciones para los gráficos de barras radiales
    radialBarOptions(label, value) {
      return {
        chart: {
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '50%',
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: '16px',
                fontWeight: 'bold',
              },
              value: {
                show: true,
                fontSize: '14px',
              },
            },
          },
        },
        labels: [label],
      }
    },
    // Calcula el porcentaje para los gráficos de barras radiales
    percentage(value) {
      return (value / 2000) * 100 // Ajusta el valor máximo según tus datos
    },
  },
}
</script>

<style scoped>
.text-success {
  color: #28a745 !important;
}
.text-primary {
  color: #007bff !important;
}
.text-warning {
  color: #ffc107 !important;
}
.text-danger {
  color: #dc3545 !important;
}
</style>
