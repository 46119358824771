<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isEditUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="onSidebarHidden"
    @change="val => $emit('update:is-edit-user-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t('Update') }} Bonus</h5>
        <i class="fas fa-times ml-1 cursor-pointer" @click="hide"></i>
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="nope"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm">
          <!-- Facility Table -->
          <b-card class="text-center mt-3">
            <b-card-header class="p-1 justify-content-center">
              <b-card-title class="font-medium-2">
                <i class="fas fa-hospital align-middle ml-2"></i>
                <span class="align-middle ml-50">Facilities</span>
              </b-card-title>
            </b-card-header>
            <treeselect
              id="mySelect"
              v-model="userData.facilities"
              :options="facilityOptions"
              :normalizer="normalizer"
              multiple />
          </b-card>

          <!-- Type User -->
          <validation-provider #default="validationContext" name="Role" rules="required">
            <b-form-group label="Type User" label-for="role">
              <treeselect
                id="status"
                v-model="userData.roles"
                :state="getValidationState(validationContext)"
                multiple
                :options="rolesOptions"
                :normalizer="normalizer" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Bonus Value -->
          <validation-provider #default="validationContext" name="Bonus Value" rules="required|numeric">
            <b-form-group label="Bonus Value" label-for="bonus-value">
              <b-form-input
                id="bonus-value"
                v-model="userData.bonus_value"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Enter bonus value" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Increase Value -->
          <validation-provider #default="validationContext" name="Increase Value" rules="required|numeric">
            <b-form-group label="Increase Value" label-for="increase-value">
              <b-form-input
                id="increase-value"
                v-model="userData.increase_value"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Enter increase value" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Increase Each -->
          <validation-provider #default="validationContext" name="Increase Each" rules="required|numeric">
            <b-form-group label="Increase Each" label-for="increase-each">
              <b-form-input
                id="increase_each"
                v-model="userData.increase_each"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Enter increase each" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Bonus Target Value -->
          <validation-provider #default="validationContext" name="Bonus Target Value" rules="required|numeric">
            <b-form-group label="Bonus Target Value" label-for="bonus-target-value">
              <b-form-input
                id="bonus-target-value"
                v-model="userData.bonus_target_value"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Enter bonus target value" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
              <span v-if="!loading">{{ $t('Update') }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button type="button" variant="outline-secondary" @click="hide">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { ref, onMounted } from '@vue/composition-api'; // Importa onMounted
import formValidation from '@/core/utils/forms/form-validation';
import SpinnerLoading from '@/components/SpinnerLoading';
import axiosR from '@/core/services/api/admin/roles';
import axiosF from '@/core/services/api/admin/facilities';
import axiosB from '@/core/services/api/admin/bonus'; // Asegúrate de importar axiosB

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    SpinnerLoading,
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isEditUserSidebarActive',
    event: 'update:is-edit-user-sidebar-active',
  },
  props: {
    isEditUserSidebarActive: {
      type: Boolean,
      required: true,
    },
    id: {
      type: [String, Number],
      default: null,
    },
  },
  setup(props, { emit }) {
    const userData = ref({
      bonus_value: '',
      increase_value: '',
      increase_each: '',
      bonus_target_value: '',
      facilities: [],
      roles: [],
    });

    const rolesOptions = ref([]);
    const facilityOptions = ref([]);
    const loading = ref(false);

    const normalizer = (node) => ({
      id: node.id,
      label: node.name,
    });

    const getRoles = () => {
      axiosR.rolesList().then((res) => {
        rolesOptions.value = res.data;
      });
    };

    const getFacilities = () => {
      axiosF.facilityList(20).then(({ data }) => {
        facilityOptions.value = data.map((item) => ({
          facilities_id: item.id,
          ...item,
        }));
      });
    };

    // Llamar a getRoles y getFacilities cuando el componente se monta
    onMounted(() => {
      getRoles();
      getFacilities();
      fetchBonusDetail();
    });

    const fetchBonusDetail = async () => {
      if (props.id) {
        try {
          const data = await axiosB.bonusDetail(props.id);
          userData.value = {
            ...userData.value,
            bonus_value: data.data.bonus_value,
            increase_value: data.data.increase_value,
            increase_each: data.data.increase_each,
            bonus_target_value: data.data.bonus_target_value,
            facilities: data.data.facilities.map((item) => item.id) || [],
            roles: data.data.roles.map((item) => item.id) || [],
          };
        } catch (error) {
          console.error('Error fetching bonus details:', error);
        }
      }
    };

    const onSubmit = async () => {
      loading.value = true;
      try {
        if (props.id) {
          await axiosB.bonusUpdate(props.id, userData.value);
          emit('editUserSuccess', true);
        }
      } catch (error) {
        console.error('Error submitting form:', error);
        emit('editUserSuccess', false);
      } finally {
        loading.value = false;
      }
    };

    const onSidebarHidden = () => {
      resetForm(); // Restablece el formulario
      emit('editUserSuccess', false); // Emite el evento de cancelación
    };

    const onCancel = () => {
      emit('editUserSuccess', false); // Emite el evento de cancelación
    };

    const resetForm = () => {
      userData.value = {
        bonus_value: '',
        increase_value: '',
        increase_each: '',
        bonus_target_value: '',
        facilities: [],
        roles: [],
      };
    };

    const { refFormObserver, getValidationState } = formValidation(resetForm);

    return {
      userData,
      rolesOptions,
      facilityOptions,
      loading,
      normalizer,
      onSubmit,
      resetForm,
      refFormObserver,
      getValidationState,
      onSidebarHidden,
      onCancel,
    };
  },
};
</script>