<template>
  <div>
    <BonusFilter
      :loading="loading"
      @searchFilter="searchFilter"
      @filterPage="filterPage" />
    <b-card>
      <TitleTable titleTable="Bonus" iconTable="fas fa-users" />
      <div class="m-2">
        <BonusEdit
          v-if="isEditUserSidebarActive"
          :is-edit-user-sidebar-active.sync="isEditUserSidebarActive"
          :id="itemEdit.id"
          @editUserSuccess="editUserSuccess" />
        <BonusAdd
          :is-add-new-user-sidebar-active.sync="isAddNewUserSidebarActive"
          @createUser="createUser" />
        <!-- Table Top -->
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <treeselect
              v-model="perPage"
              :options="perPageOptions"
              :clearable="true"
              class="w-25">
            </treeselect>
          </b-col>
          <!-- {{ $t("Search") }} -->
          <b-col cols="12" md="12">
            <div class="d-flex align-items-center justify-content-end">
              <GoBack class="mr-2" />
              <b-button
                variant="primary"
                @click="isAddNewUserSidebarActive = true"
                :disabled="loading">
                <span v-if="!loading" class="text-nowrap">{{ $t('Add') }} User</span>
                <span v-else>
                  <SpinnerLoading />
                </span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        :items="items"
        :fields="tableColumns"
        hover
        bordered
        responsive
        primary-key="id"
        class="position-relative"
        show-empty
        empty-text="No matching records found">
        <template #cell(view)="data">
          <b-form-checkbox v-model="data.detailsShowing" @change="data.toggleDetails" />
        </template>

        <!-- Column: User -->
        <template #cell(user)="data">
          <b-media vertical-align="center">
            <template #aside>
              <b-avatar
                size="32"
                :src="data.item.avatar"
                :text="data.item.name.charAt(0).toUpperCase()"
                :variant="`light-${resolveUserRoleVariant(data.item.role)}`"
                :to="{ name: 'admin-users-view', params: { id: data.item.id } }" />
            </template>
            <b-link
              :to="{ name: 'admin-users-view', params: { id: data.item.id } }"
              class="font-weight-bold d-block text-nowrap">
              {{ data.item.name }}
            </b-link>
            <small class="text-muted">@{{ data.item.name }}</small>
          </b-media>
        </template>

        <!-- Column: Role -->
        <template #cell(role)="data">
          <div class="text-nowrap">
            <i :class="resolveUserRoleIcon(data.item.roles[0].name)" class="mr-2"></i>
            -
            <span class="align-text-top text-capitalize">{{
              data.item.roles[0].name
            }}</span>
          </div>
        </template>

        <template #cell(status)="data">
          <div class="text-nowrap">
            <b-badge
              :variant="resolveUserStatusVariant(data.item.status)"
              class="badge-glow cursor-pointer"
              @click="changeStatus(data.item)">
              <span class="align-text-top text-capitalize">{{
                data.item.status ? $t('Active') : $t('Inactive')
              }}</span>
            </b-badge>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>
            <template #button-content>
              <i class="fas fa-ellipsis-v text-body"></i>
            </template>
            <!-- <b-dropdown-item v-if="$can('show', 'users')"
              :to="{ name: 'admin-users-view', params: { id: data.item.id } }">
              <i class="fas fa-eye icon-nm"></i>
              <span class="align-middle ml-2">Details</span>
            </b-dropdown-item> -->

            <b-dropdown-item
              v-if="$can('update', 'users')"
              @click="openEditSidebar(data.item)">
              <i class="fas fa-pen icon-nm"></i>
              <span class="align-middle ml-2">Edit</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('destroy', 'users')"
              @click="deleteUser(data.item)">
              <i class="fas fa-trash-alt icon-nm"></i>
              <span class="align-middle ml-2">Delete</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <PaginationTable
        :dataMeta="dataMeta"
        :totalUsers="totalUsers"
        :perPage="perPage"
        :currentPage="currentPage"
        @page-changed="pageChanged" />
    </b-card>
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BTable,
  BBadge,
  BAvatar,
  BButton,
  VBTooltip,
} from 'bootstrap-vue'
import axiosCV from '@/core/services/api/admin/bonus'

import ToastNotification from '@/components/ToastNotification'
import SpinnerLoading from '@/components/SpinnerLoading'
import PaginationTable from '@/components/PaginationTable'
import TitleTable from '@/components/TitleTable.vue'
import GoBack from '@/components/ButtonBack.vue'
import Swal from 'sweetalert2'

import BonusEdit from './BonusEdit'
import BonusAdd from './BonusAdd.vue'
import BonusFilter from './BonusFilter.vue'

import { ref } from '@vue/composition-api'
export default {
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BBadge,
    BAvatar,
    BButton,
    BonusEdit,
    BonusAdd,
    BonusFilter,
    ToastNotification,
    SpinnerLoading,
    PaginationTable,
    TitleTable,
    GoBack,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      tableColumns: [
        { key: 'increase_each', label: 'Increase Each' },
        { key: 'increase_value', label: 'Increase Value' },
        { key: 'bonus_target_value', label: 'Bonus Target Value' },
        { key: 'bonus_value', label: 'Bonus Value' },
        {
          key: 'facilities',
          label: 'Facilities',
          formatter: facilities => facilities.map(f => f.name).join(', '),
        },
        {
          key: 'roles',
          label: 'Roles',
          formatter: roles => roles.map(r => r.name).join(', '),
        },
        { key: 'actions' },
      ],
      items: null,
      loading: false,
      isEditUserSidebarActive: false,
      isAddNewUserSidebarActive: false,
      itemEdit: null,
      dataMeta: { from: 1, to: 1, of: 1 },
      totalUsers: 0,
      perPage: 10,
      currentPage: {
        page: 1,
      },
      perPageOptions: [10, 25, 50, 100].map(i => ({
        id: i,
        label: `Show ${i} Entries`,
      })),
      filtro: false,
      userFilter: null,
    }
  },
  mounted() {
    this.getUser()
  },
  watch: {
    perPage(newVal) {
      if (!this.filtro) {
        this.getUser()
      } else {
        this.searchFilter(this.userFilter)
      }
    },
  },
  methods: {
    openEditSidebar(item) {
      this.itemEdit = item
      this.isEditUserSidebarActive = true
    },
    getUser() {
      axiosCV.bonusList(this.perPage).then(data => {
        this.items = data.data.data
        this.totalUsers = data.total
        this.dataMeta.of = data.total
        this.dataMeta.from = data.from
        this.dataMeta.to = data.to
        this.currentPage.page = data.current_page
      })
    },
    editUser(item) {
      this.isEditUserSidebarActive = true
      this.itemEdit = ref(JSON.parse(JSON.stringify(item)))
    },
    editUserSuccess(value) {
      if (value) {
        this.$refs.toast.success('Type User updated successfully')
      } else {
        this.$refs.toast.error('Error updating type user')
      }
      this.isEditUserSidebarActive = false
      this.getUser()
      // this.pageChanged(this.currentPage.page)
    },
    createUser(status) {
      if (status) {
        this.$refs.toast.success('User type added successfully')
      } else {
        this.$refs.toast.danger('User type not added')
      }
      this.isAddNewUserSidebarActive = false
      this.getUser()
      // this.pageChanged(this.currentPage.page)
    },
    deleteUser(item) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this",
        icon: 'error',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          axiosCV.bonusDelete(item.id).then(response => {
            this.$refs.toast.success('User type has been deleted successfully')
            this.pageChanged(this.currentPage.page)
          })
        }
      })
    },
    pageChanged(page) {
      if (!this.filtro) {
        axiosCV.bonusPagination(this.perPage, page).then(data => {
          this.items = data.data.data
          this.totalUsers = data.total
          this.dataMeta.of = data.total
          this.dataMeta.from = data.from
          this.dataMeta.to = data.to
          this.currentPage.page = data.current_page
        })
      } else {
        axiosCV.bonusFilter(this.perPage, this.userFilter).then(data => {
          this.items = data.data.data
          this.totalUsers = data.total
          this.dataMeta.of = data.total
          this.dataMeta.from = data.from
          this.dataMeta.to = data.to
          this.currentPage.page = data.current_page
        })
      }
    },
    searchFilter(value) {
      if (this.filtro) {
        this.loading = true
        this.userFilter = value
        axiosCV
          .bonusFilter(this.perPage, value)
          .then(data => {
            this.items = data.data.data
            this.totalUsers = data.total
            this.dataMeta.of = data.total
            this.dataMeta.from = data.from
            this.dataMeta.to = data.to
            this.currentPage.page = data.current_page
            this.loading = false
          })
          .catch(() => {
            this.loading = false
          })
      } else {
        this.getUser()
      }
    },

    filterPage(value) {
      this.filtro = value
    },

    resolveUserRoleIcon(role) {
      if (role === 'FRONTDESK') return 'fas fa-phone text-warning'
      if (role === 'PMA') return 'far fa-heart text-danger'
      if (role === 'MEDICAL-ASSISTANT') return 'far fa-heart text-danger'
      if (role === 'ADMIN') return 'fas fa-server text-info'
      if (role === 'SUPER-ADMIN') return 'fas fa-server text-info'
      return 'UserIcon'
    },
    resolveUserRoleVariant(role) {
      if (role === 'FRONTDESK') return 'warning'
      if (role === 'ADMIN') return 'success'
      if (role === 'MEDICAL-ASSISTANT') return 'info'
      if (role === 'PMA') return 'info'
      if (role === 'SUPER-ADMIN') return 'danger'
      return 'primary'
    },
    resolveUserStatusVariant(status) {
      if (status) return 'success'
      return 'danger'
    },
  },
}
</script>

<style></style>
