<template>
  <b-card>
    <b-row>
      <b-col>
        <b-row align-h="between">
          <b-col cols="auto">
            <b-row>
              <b-col cols="auto">
                <b-avatar variant="primary" size="40" class="shadow mb-2">
                  <i class="fas fa-coins text-white cursor-pointer icon-2x" />
                </b-avatar>
              </b-col>
              <b-col align-self="center" cols="auto">
                <h1>Bonus Details</h1>
              </b-col>
            </b-row>
          </b-col>
          <b-col cols="auto">
            <b-dropdown right text="Filters" variant="primary">
              <b-dropdown-form>
                <b-form-checkbox-group v-model="selectedFilters">
                  <b-form-checkbox value="value_achieved">Value Achieved</b-form-checkbox>
                  <b-form-checkbox value="base_bonus">Base Bonus</b-form-checkbox>
                  <b-form-checkbox value="extra_bonus">Extra Bonus</b-form-checkbox>
                  <b-form-checkbox value="appointments_attended"
                    >Appointments Attended</b-form-checkbox
                  >
                  <b-form-checkbox value="total">Total</b-form-checkbox>
                </b-form-checkbox-group>
              </b-dropdown-form>
            </b-dropdown>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <b-row>
      <!-- Gráfico de líneas -->
      <b-col cols="9">
        <vue-apex-charts
          v-if="typeChart === 1"
          :options="chartOptions"
          :series="series"
          height="450"
          type="line" />
      </b-col>
      <!-- Gráficos de barras radiales -->
      <b-col cols="3">
        <b-row v-for="({ label, value }, key) in avgs" :key="key">
          <vue-apex-charts
            :options="radialBarOptions(label, value)"
            :series="[percentage(value)]"
            type="radialBar" />
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BDropdown,
  BDropdownForm,
  BFormCheckboxGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import axiosB from '@/core/services/api/admin/bonus'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BAvatar,
    BDropdown,
    BDropdownForm,
    BFormCheckboxGroup,
    BFormCheckbox,
    VueApexCharts,
  },
  data() {
    return {
      bonusData: [], // Datos de bonificaciones obtenidos de la API
      selectedFilters: [
        'value_achieved',
        'base_bonus',
        'extra_bonus',
        'appointments_attended',
        'total',
      ], // Filtros seleccionados
      typeChart: 1, // Tipo de gráfico (1: línea, 2: barras, etc.)
      chartOptions: {
        chart: {
          id: 'bonus-line-chart',
          toolbar: {
            show: true,
          },
        },
        xaxis: {
          categories: [], // Días (se llenará dinámicamente)
        },
        yaxis: {
          title: {
            text: 'Value',
          },
        },
        stroke: {
          curve: 'smooth',
        },
      },
      series: [], // Datos para el gráfico de líneas
      avgs: [], // Datos para los gráficos de barras radiales
    }
  },
  computed: {
    // Filtra los datos según los filtros seleccionados
    filteredBonusData() {
      return this.bonusData.map(bonus => {
        const filteredBonus = { day: bonus.day }
        if (this.selectedFilters.includes('value_achieved')) {
          filteredBonus.value_achieved = bonus.value_achieved
        }
        if (this.selectedFilters.includes('base_bonus')) {
          filteredBonus.base_bonus = bonus.base_bonus
        }
        if (this.selectedFilters.includes('extra_bonus')) {
          filteredBonus.extra_bonus = bonus.extra_bonus
        }
        if (this.selectedFilters.includes('appointments_attended')) {
          filteredBonus.appointments_attended = bonus.appointments.length
        }
        if (this.selectedFilters.includes('total')) {
          filteredBonus.total = bonus.total
        }
        return filteredBonus
      })
    },
  },
  async mounted() {
    this.loading = true // Activa el estado de carga

    // Obtén el userData desde el localStorage
    const userDataString = localStorage.getItem('userData')
    if (userDataString) {
      this.userData = JSON.parse(userDataString) // Convierte el string a objeto
    }

    try {
      // Llama a la API para obtener los datos de bonificaciones
      const response = await axiosB.bonusByUser({
        user_id: this.userData.id,
        rol_id: this.userData.roles[0].id,
        // user_id: 1,
        // rol_id: 4,
      })

      // Asigna los datos de la API a bonusData
      this.bonusData = response.data.bonus // Accede a la propiedad "bonus"

      // Prepara los datos para los gráficos
      this.prepareChartData()
      this.prepareRadialBarData()
    } catch (error) {
      console.error('Error fetching bonus details:', error)
    } finally {
      this.loading = false // Desactiva el estado de carga
    }
  },
  methods: {
    // Prepara los datos para el gráfico de líneas
    prepareChartData() {
      // Extrae el día del mes de la fecha (por ejemplo, "22" de "2025-01-22")
      this.chartOptions.xaxis.categories = this.bonusData.map(bonus => {
        const date = new Date(bonus.day) // Convierte la cadena de fecha a un objeto Date
        return date.getDate() // Obtiene el día del mes (1-31)
      })

      // Prepara las series para el gráfico de líneas
      this.series = [
        // {
        //   name: 'Value Achieved',
        //   data: this.bonusData.map(bonus => {
        //     const value = Number(bonus.value_achieved) || 0 // Convierte a número o usa 0 si no es válido
        //     return parseFloat(value.toFixed(2)) // Limita a 2 decimales
        //   }),
        // },
        {
          name: 'Base Bonus',
          data: this.bonusData.map(bonus => {
            const value = Number(bonus.base_bonus) || 0 // Convierte a número o usa 0 si no es válido
            return parseFloat(value.toFixed(2)) // Limita a 2 decimales
          }),
        },
        {
          name: 'Extra Bonus',
          data: this.bonusData.map(bonus => {
            const value = Number(bonus.extra_bonus) || 0 // Convierte a número o usa 0 si no es válido
            return parseFloat(value.toFixed(2)) // Limita a 2 decimales
          }),
        },
        {
          name: 'Total',
          data: this.bonusData.map(bonus => {
            const value = Number(bonus.total) || 0 // Convierte a número o usa 0 si no es válido
            return parseFloat(value.toFixed(2)) // Limita a 2 decimales
          }),
        },
        // {
        //   name: 'Appointments Attended',
        //   data: this.bonusData.map(bonus => bonus.appointments.length || 0), // Si no existe, usa 0
        // },
      ]
    },
    // Prepara los datos para los gráficos de barras radiales
    prepareRadialBarData() {
      // Verifica si hay datos en bonusData
      if (!this.bonusData || this.bonusData.length === 0) {
        console.error('No hay datos de bonos disponibles.')
        return
      }

      // Obtén el último bono
      const lastBonus = this.bonusData[this.bonusData.length - 1]

      // Verifica que lastBonus y sus propiedades existan
      if (!lastBonus || typeof lastBonus !== 'object') {
        console.error('El último bono no es válido.')
        return
      }

      // Convierte los valores a números y maneja casos inválidos
      const valueAchieved = this.parseNumber(lastBonus.value_achieved)
      const extraBonus = this.parseNumber(lastBonus.extra_bonus)
      const total = this.parseNumber(lastBonus.total)

      // Valores reales para mostrar en los gráficos
      this.avgs = [
        { label: 'Value Achieved', value: valueAchieved },
        { label: 'Extra Bonus', value: extraBonus },
        { label: 'Total', value: total },
      ]
    },

    // Convierte un valor a número y lo formatea con 2 decimales
    parseNumber(value) {
      const number = parseFloat(value) // Convierte a número
      if (isNaN(number)) {
        console.warn(`Valor no numérico: ${value}. Se usará 0 como valor predeterminado.`)
        return 0 // Valor predeterminado si no es un número válido
      }
      return parseFloat(number.toFixed(2)) // Formatea con 2 decimales
    },

    // Opciones para los gráficos de barras radiales
    radialBarOptions(label, value) {
      return {
        chart: {
          type: 'radialBar',
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: '50%',
            },
            dataLabels: {
              name: {
                show: true,
                fontSize: '9px',
                // fontWeight: 'bold',
                // offsetY: -8,
              },
              value: {
                show: true,
                fontSize: '14px',
                formatter: val => `${this.parseNumber(val)}`, // Muestra el valor formateado
              },
            },
          },
        },
        labels: [label],
      }
    },

    // Devuelve el valor directamente (sin cálculo de porcentaje)
    percentage(value) {
      return this.parseNumber(value) // Usa parseNumber para asegurar que sea un número válido
    },
  },
}
</script>

<style scoped>
.text-success {
  color: #28a745 !important;
}
.text-primary {
  color: #007bff !important;
}
.text-warning {
  color: #ffc107 !important;
}
.text-danger {
  color: #dc3545 !important;
}
</style>
