<template>
  <section>
    <div class="grid-container">
      <div class="grid-item">
        <welcome :userData="userData" />
        <filtro :isLoading="isLoading" class="mt-3" @buscar="buscar($event)" />
        <Visits
          class="mt-3"
          :start_date="fechas.start"
          :end_date="fechas.end"
          :dataDashboard="dataDashboard" />
      </div>
      <div class="grid-item">
        <bonus/>
        <statistics :stadistics="stadistics" />
        <totalPatiens
          class="mt-3"
          :userData="userData"
          :dataDashboard="dataDashboard"
          :fechas="fechas" />
      </div>
    </div>
  </section>
</template>

<script>
import statistics from '../components/statistics'
import Welcome from '../components/welcome'
import filtro from '../components/filter'
import totalPatiens from '../components/totalPatiens'
import Visits from '../components/visits'
import bonus from '../components/bonus'

export default {
  components: {
    Welcome,
    filtro,
    statistics,
    bonus,
    totalPatiens,
    Visits,
  },
  props: {
    userData: {
      type: Object,
      default: () => {},
      required: false,
    },
    dataDashboard: {
      type: Object,
      default: () => {},
      required: false,
    },
    isLoading: {
      type: Boolean,
      default: () => false,
      required: false,
    },
  },
  data() {
    return {
      stadistics: [],
      fechas: {
        start: `${new Date().getFullYear()}-${new Date().getMonth() + 1}-1`,
        end: `${new Date().getFullYear()}-${
          new Date().getMonth() + 1
        }-${new Date().getDate()}`,
      },
    }
  },
  watch: {
    dataDashboard() {
      this.stadistics = [
        {
          description:
            'es la facturacion diaria de la clinica, que va mostrando los cambios en tiempo real con cada cobro una vez finalizado el turno este contador se reinicia en 0 y esa facturacion total pasa al income avg',
          value: parseInt(this.dataDashboard.average.income_day),
          title: 'Daily income',
        },
        {
          description:
            'es el average diario de facturacion de la clinica, que se obtiene sumando la facturacion de todos los dias transcurridos del mes entre el numero de dias transcurridos, cambia una sola vez al dia, de un dia para otro en la madrugada',
          value: parseInt(this.dataDashboard.average.income_days),
          title: 'Income Average',
        },
      ]
    },
  },
  methods: {
    buscar(filtro) {
      this.fechas = {
        start: filtro.start,
        end: filtro.end,
      }
      this.$emit('buscar', filtro)
    },
  },
}
</script>
<style lang="scss" scoped>
@import '../assets/cssDashboards.css';
</style>
