<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isAddNewUserSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @hidden="resetForm"
    @change="val => $emit('update:is-add-new-user-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">{{ $t('Add') }} {{ $t('New') }} User</h5>

        <i class="fas fa-times ml-1 cursor-pointer" @click="hide"></i>
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form
          class="p-2"
          autocomplete="nope"
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetForm">
          <!-- Full Name -->

          <!-- Facility Table -->
          <b-card class="text-center mt-3">
            <b-card-header class="p-1 justify-content-center">
              <b-card-title class="font-medium-2">
                <i class="fas fa-hospital align-middle ml-2"></i>
                <span class="align-middle ml-50">Facilities</span>
              </b-card-title>
            </b-card-header>
            <treeselect
              id="mySelect"
              v-model="userData.facilities"
              :options="facilityOptions"
              :normalizer="normalizer"
              multiple />
          </b-card>

          <!-- Type User -->
          <validation-provider #default="validationContext" name="Role" rules="required">
            <b-form-group label="Type User" label-for="role">
              <treeselect
                id="status"
                v-model="userData.roles"
                :state="getValidationState(validationContext)"
                multiple
                :options="rolesOptions"
                :normalizer="normalizer" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Bonus Value -->
          <validation-provider
            #default="validationContext"
            name="Bonus Value"
            rules="required|numeric">
            <b-form-group label="Bonus Value" label-for="bonus-value">
              <b-form-input
                id="bonus-value"
                v-model="userData.bonus_value"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Enter bonus value" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Increase Value -->
          <validation-provider
            #default="validationContext"
            name="Increase Value"
            rules="required|numeric">
            <b-form-group label="Increase Value" label-for="increase-value">
              <b-form-input
                id="increase-value"
                v-model="userData.increase_value"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Enter increase value" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Increase Each -->
          <validation-provider
            #default="validationContext"
            name="Increase Each"
            rules="required|numeric">
            <b-form-group label="Increase Each" label-for="increase-each">
              <b-form-input
                id="increase_each"
                v-model="userData.increase_each"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Enter increase each" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Bonus Target Value -->
          <validation-provider
            #default="validationContext"
            name="Bonus Target Value"
            rules="required|numeric">
            <b-form-group label="Bonus Target Value" label-for="bonus-target-value">
              <b-form-input
                id="bonus-target-value"
                v-model="userData.bonus_target_value"
                :state="getValidationState(validationContext)"
                type="number"
                placeholder="Enter bonus target value" />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button variant="primary" class="mr-2" type="submit" :disabled="loading">
              <span v-if="!loading">{{ $t('Add') }}</span>
              <span v-else>
                <SpinnerLoading />
              </span>
            </b-button>
            <b-button type="button" variant="outline-secondary" @click="hide">
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BCard,
  BCardHeader,
  BCardTitle,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { ref } from '@vue/composition-api'
import { required, alphaNum, email } from '@validations'
import formValidation from '@/core/utils/forms/form-validation'

import SpinnerLoading from '@/components/SpinnerLoading'

import axiosB from '@/core/services/api/admin/bonus'
import axiosR from '@/core/services/api/admin/roles'
import axiosF from '@/core/services/api/admin/facilities'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    BCard,
    BCardHeader,
    BCardTitle,
    SpinnerLoading,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  model: {
    prop: 'isAddNewUserSidebarActive',
    event: 'update:is-add-new-user-sidebar-active',
  },
  props: {
    isAddNewUserSidebarActive: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        }
      },
    }
  },
  mounted() {
    const getFacilities = () => {
      axiosF.facilityList(20).then(({ data }) => {
        const facilityList = data.map(item => {
          const facilities_id = item.id
          return {
            facilities_id,
            ...item,
          }
        })
        this.facilityOptions = facilityList
      })
    }
    getFacilities()
  },
  setup(props, { emit }) {
    const blankUserData = {
      roles: [],
      facilities: [],
      bonus_value: '',
      increase_value: '',
      increase_each: '',
      bonus_target_value: '',
    }

    const userData = ref(JSON.parse(JSON.stringify(blankUserData)))
    const resetuserData = () => {
      userData.value = JSON.parse(JSON.stringify(blankUserData))
    }

    const rolesOptions = ref([])
    const getRoles = () => {
      axiosR.rolesList().then(res => {
        rolesOptions.value = res.data
      })
    }
    // Opciones de Facilities
    const facilityOptions = ref([])
    getRoles()

    const loading = ref(false)
    const onSubmit = () => {
      loading.value = true
      axiosB
        .bonusCreate(userData.value)
        .then(() => {
          loading.value = false
          resetuserData()
          emit('createUser', true)
        })
        .catch((err) => {
          loading.value = false
        })
    }
    const config = {
      altInput: true,
      altFormat: 'm-d-Y',
      dateFormat: 'm-d-Y',
      allowInput: true,
      static: true,
    }

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetuserData)

    return {
      config,
      loading,
      userData,
      onSubmit,
      rolesOptions,
      facilityOptions,
      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>

<style lang="scss">
#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
