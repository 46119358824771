<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>{{ $t("Filters") }}</h5>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col cols="12" md="3" class="mb-md-0 mb-2">
          <label>Type User</label>
          <treeselect id="rol" v-model="filters.role" :load-options="searchRoles" :async="true" :defaultOptions="true"
            :normalizer="normalizer" @keyup.enter="searchFilter" />
        </b-col>
        <b-col cols="12" class="mb-md-0 mb-2 mt-2" align="end">
          <b-button variant="warning" @click="resetFilter" class="mr-1">
            {{ $t("Clear") }}
          </b-button>
          <b-button variant="primary" @click="searchFilter" :disabled="loading">
            <span v-if="!loading">{{ $t("Search") }}</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";

import SpinnerLoading from "@/components/SpinnerLoading";
import axios from "@/core/services/api/admin/roles.js"

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    SpinnerLoading,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },

  },
  data() {
    return {
      stateOptions: [],
      filters: {
        name: null,
        status: null,
      },
      statusOptions: [
        { name: "Active", id: "true" },
        { name: "Inactive", id: "false" },
      ],
      normalizer(node) {
        return {
          id: node.id,
          label: node.name,
        };
      },
    };
  },
  methods: {
    searchFilter() {
      const filter = this.filters;
      let cont = 0;
      for (const iterator in filter) {
        if (this.filters[iterator] == "" || this.filters[iterator] == null) {
          cont++;
        }
      }
      if (Object.keys(filter).length == cont) this.$emit("filterPage", false);
      else this.$emit("filterPage", true);

      this.$emit(
        "searchFilter",
        JSON.parse(JSON.stringify(this.filters))
      );
    },
    searchRoles({ action, searchQuery, callback }) {
      if (action === "ASYNC_SEARCH") {
        const datos = {
          name: searchQuery,
        };
        axios.rolesList(datos).then(({ data }) => {
          callback(null, data);
        });
      }
    },
    resetFilter() {
      const filter = JSON.parse(JSON.stringify(this.filters));
      for (const iterator in filter) {
        this.filters[iterator] = null;
      }
    },
  },
};
</script>


